<script lang="ts">
import { defineComponent, h } from 'vue'

export default defineComponent({
  name: 'x-icon',
  render() {
    return h(
      'svg',
      {
        fill: 'black',
        viewBox: '0 0 24 24',
      },
      [
        h('path', {
          d: 'M21.159809,2C21.623091,2,22,2.376909,22,2.840191v18.319618C22,21.623091,21.623091,22,21.159809,22H2.84019C2.37691,22,2,21.623091,2,21.159809V2.840191C2,2.376909,2.37691,2,2.84019,2H21.159809ZM21.159809,1H2.84019C1.82388,1,1,1.823879,1,2.840191v18.319618C1,22.176121,1.82388,23,2.84019,23h18.319618C22.176121,23,23,22.176121,23,21.159809V2.840191C23,1.823879,22.176121,1,21.159809,1L21.159809,1Z',
        }),
        h('path', {
          d: 'M13.523985,10.775623L19.480984,4h-1.41143l-5.174801,5.88195L8.764665,4H4l6.246901,8.895341L4,20h1.411431l5.461361-6.21299L15.235336,20H20L13.523985,10.775623ZM11.590199,12.973429l-0.633908-0.886331L5.920428,5.041171h2.168246l4.065295,5.6884l0.631236,0.886331l5.283681,7.39365H15.90064L11.590199,12.973429Z',
        }),
      ]
    )
  },
})
</script>

<template>
  <div class="app-logo logo-image-wrapper" >
    <img :src="XahodLogo" alt="XAHOD" :class="logo_classes" />

    <div class="logo-info" v-if="showSlogan">
      <h1>XAHOD</h1>
      <span>XAH on Demand</span>
    </div>
  </div>
</template>


<script lang="ts">
import { defineComponent, PropType, computed, defineAsyncComponent } from 'vue'
import XahodLogo from '../assets/xahod_logo.svg'
enum SCHEMES {
  primary = 'primary',
  whiteOnPrimary = 'white-on-primary',
  whiteOnBlack = 'white-on-black', // Black = tertiary? TODO: Revisit this at some point.
  blackOnWhite = 'black-on-white',
}

enum MODIFICATIONS {
  big = 'big',
  medium = 'medium',
  small = 'small',
}

export default defineComponent({
  name: 'app-logo',
  props: {
    showSlogan: { type: Boolean, default: false },
    schemes: {
      type: String,
      default: SCHEMES.primary,
      validator: (value: string) => {
        return (Object.values(SCHEMES) as string[]).includes(value);
      },
    },
    modifications: {
      type: String,
      default: MODIFICATIONS.medium,
      validator: (value: string) => {
        return (Object.values(MODIFICATIONS) as string[]).includes(value);
      },
    },
  },
  setup(props) {
    const logo_classes = computed(() => {
      const schemes = props.schemes
        .split(' ')
        .filter((el) => Boolean(el))
        .map((el) => `app-logo--${el}`)
      const modifications = props.modifications
        .split(' ')
        .filter((el) => Boolean(el))
        .map((el) => `app-logo--img--${el}`)
      return ['app-logo--img', ...schemes, ...modifications].join(' ')
    })

    return {
      XahodLogo,
      logo_classes,
    }
  },
})
</script>

<style lang="scss" scoped>
.app-logo {
  display: flex;
  flex-direction: row;
  position: relative;
}
.app-logo--img {
  // &--primary {
  //   // TODO: Manage svg in css
  // }
  display: block;
  height: auto;

  &--big {
    min-height: toRem(60);
    width: 100%;
  }

  &--medium {
    width: 150px;
    margin: auto;
  }

  &--small {
    width: 70px;
  }
}
</style>

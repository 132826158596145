import '@/styles/app.scss'
import 'virtual:svg-icons-register'

/// <reference types="vite/client" />
/// <reference types="vite-svg-loader" />

import App from '@/App.vue'
import log from 'loglevel'

import VueToastificationPlugin from 'vue-toastification'
import { ICON_NAMES } from '@/enums'
import { createApp, getCurrentInstance, h } from 'vue'
import { useI18n } from 'vue-i18n'
import { i18n } from '@/localization'
import { config } from '@config'
import { router } from '@/router'
import { store } from '@/store'
import * as Sentry from '@sentry/vue'
import { Bus } from './helpers'
import Vue3TouchEvents, {
  type Vue3TouchEventsOptions,
} from "vue3-touch-events";

function setupErrorMonitors() {
  function initSentry() {
    Sentry.init({
      app,
      dsn:
        config.ENVIRONMENT === 'production'
          ? 'https://770f1dfce2cdf1da5e484d9e74734dcf@o4506814582292480.ingest.us.sentry.io/4507114884825088'
          : '',
      environment: config.ENVIRONMENT,
      integrations: [
        Sentry.browserTracingIntegration({ router, enableInp: true }),
        Sentry.browserProfilingIntegration(),
        Sentry.extraErrorDataIntegration({ depth: 6 }),
        Sentry.sessionTimingIntegration(),
        Sentry.httpClientIntegration({
          failedRequestStatusCodes: [[400, 599]],
        }),
        Sentry.replayIntegration({
          stickySession: true,
          maskAllText: false,
          blockAllMedia: false,
          maskAllInputs: false,
          networkDetailAllowUrls: [
            'xahod-bebbhke2fvhmf9f3.z02.azurefd.net',
            'xahod.uaenorth.cloudapp.azure.com',
            'xumm.app/api/v1/jwt/authorize',
            'xumm.app',
            window.location.origin,
            'https://xahod.uaenorth.cloudapp.azure.com',
            'https://xumm.app/api/v1/jwt/authorize',
            'https://xumm.app',
            'https://xahod-bebbhke2fvhmf9f3.z02.azurefd.net',
            'https://xumm.com/api',
            'https://xumm.app/api/v1/jwt/ping',
          ],
          networkRequestHeaders: [
            'Cache-Control',
            'Authorization',
            'Content-Type',
            'Accept',
            'Origin',
            'User-Agent',
            'Referer',
            'Host',
            'Connection',
            'Accept-Encoding',
            'Accept-Language',
            'Cookie',
            'Pragma',
            'If-Modified-Since',
            'If-Match',
            'If-None-Match',
            'If-Range',
            'If-Unmodified-Since',
            'Range',
            'X-API-KEY',
          ],
        }),
      ],
      replaysOnErrorSampleRate: 1.0,
      tracesSampleRate: 1.0,
      profilesSampleRate: 0.4,
      replaysSessionSampleRate: 0.4,
      attachStacktrace: true,
      tracePropagationTargets: [
        // CAUTION: Entering a new domain here (like xumm.app) will break the app as Sentry's request middleware will cause interference
        window.location.origin,
        'xahod.uaenorth.cloudapp.azure.com',
        /^https:\/\/xahod-bebbhke2fvhmf9f3\.z02\.azurefd\.net\/api/,
        /^https:\/\/xahod\.uaenorth\.cloudapp\.azure\.com\/api/,
      ],
      sendDefaultPii: true,
    })
  }

  // Add NewRelic here instead of index.html head
  initSentry()
}

router.afterEach((to, from) => {
  // This controls the loader for App.vue
  Bus.emit('loader-status', false)
})

const app = createApp({
  setup() {
    const app = getCurrentInstance()
    const { t, locale } = useI18n({ useScope: 'global' })
    if (app) {
      app.appContext.config.globalProperties.$t = t
      app.appContext.config.globalProperties.$locale = locale
    }
  },
  render: () => h(App),
})

setupErrorMonitors()


log.setDefaultLevel(config.LOG_LEVEL)

app.use(router).use(store).use(i18n).use(VueToastificationPlugin).use<Vue3TouchEventsOptions>(Vue3TouchEvents, {
  disableClick: false
  // any other global options...
})

app.config.globalProperties.$config = config
app.config.globalProperties.$icons = ICON_NAMES
if(config.ENVIRONMENT === 'development') {
  app.config.performance = true
}
app.config.errorHandler = function (err, vm, info) {
  log.error(`Error: ${err}; Info: ${info}`)
}

app.mount('#app')



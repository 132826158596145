<template>
  <div class="loader">
    <template v-if="scheme === SCHEMES.spinner">
      <hollow-dots-spinner :color="color" />
      <!-- <spinner /> -->
      {{ msg }}
    </template>
  </div>
</template>

<script lang="ts">
import HollowDotsSpinner from '@/common/HollowDotsSpinner/HollowDotsSpinner.vue'

import { defineComponent } from 'vue'

const SCHEMES = {
  spinner: 'spinner',
}

export default defineComponent({
  name: 'loader',
  components: { HollowDotsSpinner },
  props: {
    scheme: {
      type: String,
      default: SCHEMES.spinner,
    },
    msg: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'black',
    },
  },
  setup() {
    return {
      SCHEMES,
    }
  },
})
</script>

<style lang="scss" scoped>
.loader {
  display: grid;
  place-items: center;
}
</style>

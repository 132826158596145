import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
  useRoute,
  useRouter,
} from 'vue-router'

import { ROUTE_NAMES } from '@/enums'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: ROUTE_NAMES.app, // Main page, encapsulates the entire app
    component: () => import('@/pages/AppPage.vue'),
    children: [
      {
        path: 'interface', // Note: no leading slash for nested paths
        name: ROUTE_NAMES.interface,
        component: () => import('@/interface/Interface.vue'),
      },
      // Add other sub-routes here if needed
    ],
  },
  // Catch-all route can still be defined here, outside of the children array
  // {
  //   path: '/:catchAll(.*)',
  //   name: 'NotFound',
  //   component: () => import('@/pages/NotFound.vue'),
  // },
];


const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
  scrollBehavior: () => ({ top: 0, left: 0 }),
})



export { router }

<template>
  <div class="disclaimer-message">
    <icon class="disclaimer-message__icon" :name="iconName" />
    <h3 v-if="title" class="disclaimer-message__title">
      {{ title }}
    </h3>
    <p class="disclaimer-message__message">
      {{ message }}
    </p>
  </div>
</template>

<script lang="ts">
import { Icon } from '@/common'

import { ICON_NAMES } from '@/enums'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'disclaimer',
  components: { Icon },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
    iconName: {
      type: String as PropType<ICON_NAMES>,
      default: ICON_NAMES.check,
    },
  },
})
</script>

<style lang="scss" scoped>
.disclaimer-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.disclaimer-message__icon {
  color: var(--col-primary-light);
  font-size: toRem(48);
  margin-bottom: toRem(12);
}

.disclaimer-message__title {
  color: var(--col-primary-main);
  font-size: toRem(24);
}

.disclaimer-message__message {
  color: var(--col-primary-main);
  font-size: toRem(18);
}
</style>

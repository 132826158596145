<template>
  <span class="version-tag"> v1.2.9 </span>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'version-tag',
})
</script>

<style lang="scss" scoped>
.version-tag {
  position: absolute;
  color: var(--xahod-black);
  bottom: 0;
  right: 2px;
}
</style>

<template>
  <div class="hollow-dots-spinner" :style="spinnerStyle">
    <div
      v-for="(ds, index) in dotsStyles"
      :key="index"
      class="dot"
      :style="ds"
    ></div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'HollowDotsSpinner',
  props: {
    animationDuration: {
      type: Number,
      default: 1000,
    },
    dotSize: {
      type: Number,
      default: 15,
    },
    dotsNum: {
      type: Number,
      default: 3,
    },
    color: {
      type: String,
      default: '#fff',
    },
  },
  setup(props) {
    const horizontalMargin = computed(() => props.dotSize / 2);

    const spinnerStyle = computed(() => ({
      height: `${props.dotSize}px`,
      width: `${(props.dotSize + horizontalMargin.value * 2) * props.dotsNum}px`,
    }));

    const dotStyle = computed(() => ({
      animationDuration: `${props.animationDuration}ms`,
      width: `${props.dotSize}px`,
      height: `${props.dotSize}px`,
      margin: `0 ${horizontalMargin.value}px`,
      borderWidth: `${props.dotSize / 5}px`,
      borderColor: props.color,
    }));

    const dotsStyles = computed(() => {
      const dotsStyles = [];
      const delayModifier = 0.3;
      const basicDelay = props.animationDuration;

      for (let i = 1; i <= props.dotsNum; i++) {
        dotsStyles.push({
          animationDelay: `${basicDelay * i * delayModifier}ms`,
          ...dotStyle.value,
        });
      }

      return dotsStyles;
    });

    return {
      spinnerStyle,
      dotsStyles,
    };
  },
};
</script>

<style scoped>
.hollow-dots-spinner,
.hollow-dots-spinner * {
  box-sizing: border-box;
}
.hollow-dots-spinner {
  height: 15px;
  width: 90px;
}
.hollow-dots-spinner .dot {
  width: 15px;
  height: 15px;
  margin: 0 7.5px;
  border: 3px solid #ff1d5e;
  border-radius: 50%;
  float: left;
  transform: scale(0);
  animation: hollow-dots-spinner-animation 1s ease infinite 0ms;
}
.hollow-dots-spinner .dot:nth-child(1) {
  animation-delay: 0.3s;
}
.hollow-dots-spinner .dot:nth-child(2) {
  animation-delay: 0.6s;
}
.hollow-dots-spinner .dot:nth-child(3) {
  animation-delay: calc(0.3s * 3);
}
@keyframes hollow-dots-spinner-animation {
  50% {
    transform: scale(1);
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>

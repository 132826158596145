<template>
  <div>
    <div v-if="isAppInitialized" class="app__container">
      <div v-if="maintenance" class="app__load_maintenance">
        <AppLogo v-touch:hold="touchHoldHandler" v-touch-options="{touchClass: 'active', swipeTolerance: 80, touchHoldTolerance: 5000}"
          class="app-page__logo"
          schemes="primary"
          modifications="medium"
        ></AppLogo>
        <h3>{{ maintenanceMessage }}</h3>

        <br />
        <h4 style="color: black">{{ maintenanceMessage2 }}</h4>

        <br />
        <br />
        <h4 style="color: black">
          {{ maintenanceMessage3 }}
          <xicon
            style="display: inline-block; vertical-align: middle; width: 25px"
          ></xicon>
          <a
            style="font-weight: 700; font-size: 1em"
            href="https://twitter.com/XAHOnDemand"
          >
            @</a
          >
          <a
            style="font-weight: 800; font-size: 1em; text-decoration: underline"
            href="https://twitter.com/XAHOnDemand"
            >XAHOnDemand</a
          >
        </h4>
        <br />
        <br />
      </div>
      <div v-else-if="initializedWithFailure" class="app__load_failure">
        <AppLogo
          class="app-page__logo"
          schemes="primary"
          modifications="medium"
        ></AppLogo>
        <h3>{{ failureReason }}</h3>
        <br />
        <span> {{ failureReasonBody }}</span>
        <br />
        <div style="display: flex; justify-content: center; width: 90%">
          <div style="text-align: left">
            <h3 style="font-size: 1.2em">
              In the meantime, here is what you can do:
            </h3>
            <ul>
              <li class="troubleshoot__item">- Reload the XAHOD xApp</li>
              <li class="troubleshoot__item">- Try again in 30 minutes</li>
              <li class="troubleshoot__item">
                - Open a ticket at
                <a style="font-weight: 700" href="https://xahod.com/support"
                  >xahod.com/support </a
                >and tell us what happened
              </li>
            </ul>
          </div>
        </div>
        <version-tag />
      </div>
      <div v-else>
        <router-view v-slot="{ Component, route }">
          <transition>
            <component :is="Component" :route="route" />
          </transition>
        </router-view>
      </div>
    </div>
    <div v-else>
      <loader class="loaderStyle withCover" :color="'black'" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useNotifications } from '@/composables'
import { config } from '@config'
import { Loader, VersionTag } from '@/common'
import * as Sentry from '@sentry/vue'
import xicon from '@/utils/xicon.vue'
import { Bus } from './helpers'
import AppLogo from '@/common/AppLogo.vue'

export default defineComponent({
  name: 'app',
  components: { AppLogo, Loader, VersionTag, xicon },

  setup() {
    //const Async = defineAsyncComponent(() => import(`../src/assets/icons/x-icon.svg`))
    const isAppInitialized = ref(false)
    const isLoading = ref(true)
    const initializedWithFailure = ref(false)
    const failureReason = ref('Hmm... Something went wrong.')
    const maintenance = ref(true)
    const failureReasonBody = ref(
      'Sorry, XAHOD is not working properly. We now know about this and are working to fix it.',
    )

    const maintenanceMessage = ref('🔧 XAHOD Patching in progress. ')
    const maintenanceMessage2 = ref(
      'We will be back in a moment. Stand by and thanks for your patience! 🙏🏽',
    )
    const maintenanceMessage3 = ref(
      'For the latest status updates, visit us on ',
    )

    const init = () => {
      isLoading.value = true
      try {
        useNotifications()
        document.title = config.APP_NAME

        isAppInitialized.value = true

        if (maintenance.value) {
          Sentry.captureMessage('App initialized with maintenance mode')
          isLoading.value = false
        }

        // skipcq: JS-0002
        console.log('App initialized')
      } catch (error) {
        isAppInitialized.value = true
        initializedWithFailure.value = true
        Sentry.setExtra('error', error)
        Sentry.captureException(error)
        console.error('App initialized with failure, error unknown', error)
      }
    }

    init()

    const touchHoldHandler = (event: Event) => {
      console.log('Touch hold', event)
      console.log("Changed maintenance mode to false")
      maintenance.value = false
    }

    Bus.on('loader-status', status => {
      isLoading.value = Boolean(status)
    })

    return {
      isAppInitialized,
      initializedWithFailure,
      failureReason,
      failureReasonBody,
      isLoading,
      maintenance,
      maintenanceMessage,
      maintenanceMessage2,
      maintenanceMessage3,
      touchHoldHandler
    }
  },
})
</script>

<style lang="scss" scoped>
.app__container {
  display: grid;
  flex: 1;
}

.loaderStyle {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  color: black;
}

.app__load_maintenance {
  display: flex;
  min-height: 85vh;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  padding-left: 3%;
  padding-right: 3%;

  h3 {
    margin-top: 20px;
    color: var(--color-text);
  }

  span {
    color: var(--color-text);
  }
}

.loaderStyle.withCover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.troubleshoot__item {
  color: black;
}

.v-enter-active {
  transition: opacity 0.8s;
}

.v-enter-from {
  opacity: 0;
}
</style>

<template>
  <div class="error-message">
    <icon class="error-message__icon" :name="iconName" />
    <h3 v-if="title" class="error-message__title">
      {{ title }}
    </h3>
    <br />
    <span class="error-message__message">
      {{ message }}
    </span>
    <br />
    <div  style="display: flex; justify-content: center; width: 90%">
      <div style="text-align: left">
        <h3 v-if="unknownError" style="font-size: 1.2em">
          In the meantime, here is what you can do:
        </h3>
        <h3 v-else style="font-size: 1.2em">
          If that doesn't work, here is what you can do:
        </h3>
        <ul>
          <li class="troubleshoot__item">- Reload the XAHOD xApp</li>
          <li class="troubleshoot__item">- Try again in 30 minutes</li>
          <li class="troubleshoot__item">
            - Open a ticket at
            <a style="font-weight: 700" href="https://xahod.com/support"
              >xahod.com/support </a
            >and tell us what happened
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Icon } from '@/common'

import { ICON_NAMES } from '@/enums'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'error-message',
  components: { Icon },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      required: true,
    },
    unknownError: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String as PropType<ICON_NAMES>,
      default: ICON_NAMES.exclamationCircle,
    },
  },
})
</script>

<style lang="scss" scoped>
.error-message {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  display: flex;
  min-height: 60vh;
  vertical-align: middle;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  padding-left: 3%;
  padding-right: 3%;

  h3 {
    margin-top: 20px;
    color: var(--color-text);
  }

  span {
    color: var(--color-text);
  }
}

.troubleshoot__item {
  color: black;
}

.error-message__icon {
  // color: var(--col-error-light);
  color: black;
  font-size: toRem(20);
  margin-bottom: toRem(8);
}

.error-message__title {
  color: var(--col-error-main);
  font-size: toRem(32);
  margin-top: 20px;
}

.error-message__message {
  color: var(--col-error-main);
  // font-size: toRem(18);
}
</style>

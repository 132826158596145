import packageJson from '../package.json'
import { LogLevelDesc } from 'loglevel'

interface AppConfig {
  ENVIRONMENT: string
  APP_NAME: string
  API_URL: string
  EXPLORER_XUMM: string
  LOG_LEVEL: LogLevelDesc
  BUILD_VERSION: string
  XAHOD_XRP_WALLET: string
  XAPP_API_KEY: string
  XAHOD_CORE_API_URL: string
  APP_ANALYZE: boolean
}
// TODO C: Load endpoints from the Xahod / Xaman API instead, including additonal config options
export const config: AppConfig = {
  ENVIRONMENT: import.meta.env.VITE_APP_ENVIRONMENT,
  API_URL: import.meta.env.VITE_API_URL,
  APP_NAME: import.meta.env.VITE_APP_NAME,
  XAHOD_XRP_WALLET: import.meta.env.VITE_APP_XAHOD_XRP_WALLET,
  EXPLORER_XUMM: 'https://xumm.app/explorer/',
  LOG_LEVEL: 'trace',
  BUILD_VERSION: packageJson.version || import.meta.env.VITE_APP_BUILD_VERSION,
  XAPP_API_KEY: import.meta.env.VITE_APP_XAPP_KEY,
  XAHOD_CORE_API_URL: import.meta.env.VITE_APP_XAHOD_API_URL,
  APP_ANALYZE: import.meta.env.VITE_APP_ANALYZE === 'true',
} as const

Object.assign(config, _mapEnvCfg(import.meta.env))
Object.assign(config, _mapEnvCfg(document.ENV))

function _mapEnvCfg(env: ImportMetaEnv | typeof document.ENV): {
  [k: string]: string | boolean | undefined
} {
  return Object.keys(env).reduce(
    (acc, key) => {
      if (key.startsWith('VITE_APP_')) {
        const newKey = key.replace(/^VITE_APP_/, '') // Remove the prefix
        acc[newKey] = env[key] // Assign the new key with the value from env
      }
      return acc // Return the accumulator for the next iteration
    },
    {} as { [k: string]: string | boolean | undefined }
  )
}
